<template>
  <form
    class="inspection-form"
    ref="form"
    @submit.prevent="submit"
    style="width: 100%"
  >
    <div class="inspection-form-group width-1 gallery">
      <label>{{ $t("VEHICLES.EXTERIOR_PHOTOS") }}</label>

      <gallery-selector
        :label="`${$t('VEHICLES.EXTERIOR_PHOTOS')}`"
        :defaultGallery="vehicle.exterior_photos"
        ressource_name="vehicles"
        :ressource_id="vehicle.id ? vehicle.id : 0"
        field="exterior_photos"
        :disabled="disable"
        :required="true"
        @galleryChanged="
          (exteriorPhotos) => {
            vehicle.exterior_photos = exteriorPhotos;
          }
        "
      />

      <validation-error :errors="apiValidationErrors.exterior_photos" />
    </div>

    <div class="inspection-form-group width-1 gallery">
      <label>{{ $t("VEHICLES.INTERIOR_PHOTOS") }}</label>

      <gallery-selector
        :label="`${$t('VEHICLES.INTERIOR_PHOTOS')}`"
        :defaultGallery="vehicle.interior_photos"
        ressource_name="vehicles"
        :ressource_id="vehicle.id ? vehicle.id : 0"
        field="interior_photos"
        :disabled="disable"
        :required="true"
        @galleryChanged="
          (interiorPhotos) => {
            vehicle.interior_photos = interiorPhotos;
          }
        "
      />

      <validation-error :errors="apiValidationErrors.interior_photos" />
    </div>

    <div class="inspection-form-group width-1 gallery">
      <div style="display: flex; gap: 2em; align-items: center">
        <div>{{ $t("VEHICLES.DAMAGES") }}</div>

        <base-button
          class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm"
          icon
          size="sm"
          @click="() => (open = true)"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-edit"></i>
          </span>
        </base-button>

        <modal :show="open" modal-classes="modal-secondary text-left">
          <h2>Spécifier les emplacements</h2>

          <p>
            Vous pouvez assigner une localisation précise aux dommages capturés
            en photo
          </p>

          <div class="row" v-for="(item, key) in vehicle.damages" :key="key">
            <div class="col-6">
              <gallery-selector
                :label="`${$t('COMMON.PICTURE')}`"
                :defaultGallery="[item.photo]"
                ressource_name="vehicles"
                :ressource_id="vehicle.id ? vehicle.id : 0"
                field="exterior_photos"
                disabled
              />
            </div>

            <div class="col-6">
              <base-input
                v-model="item.location"
                :label="`${$t('COMMON.LOCATION')}`"
                :placeholder="`${$t('COMMON.LOCATION')}`"
                :disable="disable"
                required
              >
                <damage-location-selector
                  :filterable="true"
                  :value="item.location"
                  @locationChanged="
                    (location) => {
                      item.location = location;
                    }
                  "
                />
              </base-input>
            </div>
          </div>

          <template slot="footer">
            <button
              type="button"
              class="btn btn-elite black"
              @click="() => (open = false)"
            >
              {{ $t("COMMON.CLOSE") }}
            </button>

            <button
              type="button"
              class="btn ml-auto btn-elite save"
              @click="() => (open = false)"
            >
              {{ $t("NOTIFICATIONS.SAVE") }}
            </button>
          </template>
        </modal>
      </div>

      <gallery-selector
        :label="`${$t('VEHICLES.DAMAGES')}`"
        :defaultGallery="vehicle.damages.map((map) => map.photo)"
        ressource_name="vehicles"
        :ressource_id="vehicle.id ? vehicle.id : 0"
        field="damages"
        :disabled="disable"
        @galleryChanged="
          (photos) => {
            vehicle.damages = photos.map((photo, idx) => {
              return {
                photo,
                location: vehicle.damages[idx]?.location ?? '',
              };
            });
          }
        "
      />

      <validation-error :errors="apiValidationErrors.damages" />
    </div>
  </form>
</template>

<script>
import _, { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import DamageLocationSelector from "@/components/DamageLocationSelector.vue";

export default {
  name: "step3",
  components: {
    BaseInput,
    ValidationError,
    GallerySelector,
    DamageLocationSelector,
  },

  mixins: [formMixin],

  props: {
    vehicleData: { type: Object },
    formErrors: {
      type: Array,
      default: () => [],
    },
    loading: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
  },

  data() {
    let vehicleData = cloneDeep(this.vehicleData);

    return {
      vehicle: vehicleData,
      open: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    isFormValid() {
      return this.$refs.form.checkValidity();
    },
    submit() {
      this.$emit("submitted", {
        ...cloneDeep(this.vehicle),
        current_step: 2,
      });
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData(vehicleData) {
      if (vehicleData) {
        this.vehicle = cloneDeep(vehicleData);
      }
    },
    vehicle: {
      handler: function (value) {
        if (_.isEqual(value, this.vehicle)) return;

        this.$emit("formChanged", this.vehicle);
      },
      deep: true,
    },
  },
};
</script>
