<template>
  <form
    class="inspection-form"
    ref="form"
    @submit.prevent="submit"
    style="width: 100%"
  >
    <div
      class="inspection-form-group width-1"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="vehicle.organization?.id"
          :filterable="true"
          :showAll="false"
          :disabled="disable"
          required
          @organizationChanged="
            (organizationId) => {
              vehicle.location = { id: '', type: 'location' };

              vehicle.organization = {
                id: organizationId,
                type: 'organizations',
              };
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div
      class="inspection-form-group width-1"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
      >
        <locations-selector
          :locations="vehicle.location?.id ? [vehicle.location] : []"
          :organization="vehicle.organization?.id"
          :disabled="disable"
          @locationsChanged="
            (locations) => {
              vehicle.location = locations[0];
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        :label="`${$t('VEHICLES.PICKUP_LOCATION')}`"
        :placeholder="`${$t('VEHICLES.PICKUP_LOCATION')}`"
      >
        <addresses-selector
          :address="vehicle.pickup_location"
          @addressInputChangeChanged="
            (values) => {
              vehicle.pickup_location = values.formatted_address ?? '';
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.pickup_location" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.auction_type"
        :label="`${$t('AUCTIONS.AUCTION_TYPE')}`"
        :disable="disable"
        :required="true"
        :placeholder="`${$t('AUCTIONS.AUCTION_TYPE')}`"
      >
        <auction-type-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.auction_type"
          @auctionTypeChanged="
            (type) => {
              vehicle.auction_type = type;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.auction_type" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.auction_date"
        type="date"
        :label="`${$t('VEHICLES.AUCTION_DATE')}`"
        :placeholder="`${$t('VEHICLES.AUCTION_DATE')}`"
        :disable="disable"
        required
      />

      <validation-error :errors="apiValidationErrors.auction_date" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.buy_now_price"
        type="number"
        :label="`${$t('VEHICLES.BUY_NOW_PRICE')}`"
        :placeholder="`${$t('VEHICLES.BUY_NOW_PRICE')}`"
        :disable="disable"
        required
      />

      <validation-error :errors="apiValidationErrors.buy_now_price" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.min_bid_amount"
        type="number"
        :label="`${$t('VEHICLES.MIN_BID_AMOUNT')}`"
        :placeholder="`${$t('VEHICLES.MIN_BID_AMOUNT')}`"
        :disable="disable"
        required
      />

      <validation-error :errors="apiValidationErrors.min_bid_amount" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.reserve_price"
        type="number"
        :label="`${$t('VEHICLES.RESERVE_PRICE')}`"
        :placeholder="`${$t('VEHICLES.RESERVE_PRICE')}`"
        :disable="disable"
        required
      />

      <validation-error :errors="apiValidationErrors.reserve_price" />
    </div>
  </form>
</template>

<script>
import _, { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import AddressesSelector from "@/components/AddressesSelector.vue";
import AuctionTypeSelector from "@/components/AuctionTypeSelector.vue";
import moment from "moment";

export default {
  name: "step4",
  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    AddressesSelector,
    AuctionTypeSelector,
  },

  mixins: [formMixin],

  props: {
    vehicleData: { type: Object },
    formErrors: {
      type: Array,
      default: () => [],
    },
    loading: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
  },

  data() {
    let vehicleData = cloneDeep(this.vehicleData);

    return {
      vehicle: vehicleData,
    };
  },

  computed: {},

  created() {},

  methods: {
    isFormValid() {
      return this.$refs.form.checkValidity();
    },
    submit() {
      this.$emit("submitted", {
        ...cloneDeep(this.vehicle),
        current_step: 3,
        auction_date: this.vehicle.auction_date
          ? moment(this.vehicle.auction_date).toISOString()
          : null,
      });
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData(vehicleData) {
      if (vehicleData) {
        this.vehicle = cloneDeep(vehicleData);
      }
    },
    vehicle: {
      handler: function (value) {
        if (_.isEqual(value, this.vehicle)) return;

        this.$emit("formChanged", this.vehicle);
      },
      deep: true,
    },
  },
};
</script>
