<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!vehicle">
      <span class="loader"></span>
    </span>

    <div v-if="vehicle" class="elite-tabs-wrapper">
      <div class="tabs-wrapper">
        <tabs
          fill
          class="flex-column flex-md-row"
          tabNavWrapperClasses="nav-wrapper tabs-wrapper-nav"
          tabNavClasses="nav elite-tabs"
          value="global"
        >
          <tab-pane title="global" id="1" :active="true">
            <span slot="title">
              <i class="ni ni-cloud-upload-96"></i>
              {{ $t("COMMON.GLOBAL") }}
            </span>
            <vehicle-view-global :vehicle="vehicle" />
          </tab-pane>

          <tab-pane title="pictures" id="2" :active="true">
            <span slot="title">
              <i class="fa fa-image"></i>
              {{ $t("COMMON.PICTURES") }}
            </span>
            <vehicle-view-images :vehicle="vehicle" />
          </tab-pane>

          <tab-pane
            title="files"
            id="998"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
          >
            <span slot="title">
              <i class="ni ni-folder-17"></i>
              {{ $t("COMMON.FILES") }}
            </span>
            <div class="elite-tabs-wrapper-content">
              <list-file-component :object="vehicle" />
            </div>
          </tab-pane>

          <tab-pane
            title="logs"
            id="999"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
          >
            <span slot="title">
              <i class="fa fa-file"></i>
              {{ $t("COMMON.LOGS") }}
            </span>
            <vehicle-view-logs :vehicle="vehicle" />
          </tab-pane>

          <tab-pane  :active="true">
            <span slot="title">
              <i class="ni ni-cloud-upload-96"></i>
              {{ $t("COMMON.DEALERS") }}
            </span>
            <vehicle-organization-view-global :vehicle="vehicle" />
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import VehicleViewGlobal from "../partials/VehicleViewGlobal.vue";
import VehicleViewLogs from "../partials/VehicleViewLogs.vue";
import VehicleViewImages from "../partials/VehicleViewImages.vue";
import VehicleOrganizationViewGlobal from "../partials/VehicleOrganizationViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    VehicleViewGlobal,
    VehicleViewLogs,
    ListFileComponent,
    VehicleViewImages,
    VehicleOrganizationViewGlobal ,
  },

  mixins: [requestErrorMixin],

  props: {
    vehicleId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      vehicle: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("vehicles/get", this.vehicleId);
        this.vehicle = this.$store.getters["vehicles/vehicle"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
