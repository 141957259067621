var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"form",staticClass:"inspection-form",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.CONDITION')}`,"placeholder":`${_vm.$t('VEHICLES.CONDITION')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.condition),callback:function ($$v) {_vm.$set(_vm.vehicle, "condition", $$v)},expression:"vehicle.condition"}},[_c('condition-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.condition},on:{"conditionChanged":(condition) => {
            _vm.vehicle.condition = condition;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.condition}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('label',[_vm._v(_vm._s(_vm.$t("VEHICLES.OWNERSHIP_CAN_BE_TRANSFERRED")))]),_c('base-checkbox',{attrs:{"label":`${_vm.$t('VEHICLES.OWNERSHIP_CAN_BE_TRANSFERRED')}`,"disabled":_vm.disable},on:{"change":function($event){return _vm.handleCheckboxChange(
          _vm.vehicle.ownership_can_be_transferred,
          'ownership_can_be_transferred'
        )}},model:{value:(_vm.vehicle.ownership_can_be_transferred),callback:function ($$v) {_vm.$set(_vm.vehicle, "ownership_can_be_transferred", $$v)},expression:"vehicle.ownership_can_be_transferred"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.ownership_can_be_transferred}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('label',[_vm._v(_vm._s(_vm.$t("VEHICLES.MUST_BE_TOWED")))]),_c('base-checkbox',{attrs:{"label":`${_vm.$t('VEHICLES.MUST_BE_TOWED')}`,"placeholder":`${_vm.$t('VEHICLES.MUST_BE_TOWED')}`,"disable":_vm.disable,"required":true},on:{"change":function($event){return _vm.handleCheckboxChange(_vm.vehicle.must_be_towed, 'must_be_towed')}},model:{value:(_vm.vehicle.must_be_towed),callback:function ($$v) {_vm.$set(_vm.vehicle, "must_be_towed", $$v)},expression:"vehicle.must_be_towed"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.must_be_towed}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.CARFAX_RECLAIMS')}`,"disable":_vm.disable,"required":_vm.vehicle.carfax_reporting_enabled == true,"placeholder":`${_vm.$t('VEHICLES.CARFAX_RECLAIMS')}`},model:{value:(_vm.vehicle.carfax_reclaims),callback:function ($$v) {_vm.$set(_vm.vehicle, "carfax_reclaims", $$v)},expression:"vehicle.carfax_reclaims"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.carfax_reclaims}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('label',[_vm._v(_vm._s(_vm.$t("VEHICLES.CARFAX_REPORTING_ENABLED")))]),_c('base-radio',{attrs:{"name":"carfax_reporting_enabled","radio-value":true,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.CARFAX_REPORTING_ENABLED')}`},on:{"input":(value) => {
          _vm.vehicle.carfax_reporting_enabled = value;
        }},model:{value:(_vm.vehicle.carfax_reporting_enabled),callback:function ($$v) {_vm.$set(_vm.vehicle, "carfax_reporting_enabled", $$v)},expression:"vehicle.carfax_reporting_enabled"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.YES"))+" ")]),_c('base-radio',{attrs:{"name":"carfax_reporting_enabled","radio-value":false,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.CARFAX_REPORTING_ENABLED')}`},on:{"input":(value) => {
          _vm.vehicle.carfax_reporting_enabled = value;
        }},model:{value:(_vm.vehicle.carfax_reporting_enabled),callback:function ($$v) {_vm.$set(_vm.vehicle, "carfax_reporting_enabled", $$v)},expression:"vehicle.carfax_reporting_enabled"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.NO"))+" ")]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.carfax_reporting_enabled}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.CARFAX_API')}`,"placeholder":`${_vm.$t('VEHICLES.CARFAX_API')}`,"disable":_vm.disable,"required":_vm.vehicle.carfax_reporting_enabled == true},model:{value:(_vm.vehicle.carfax_api),callback:function ($$v) {_vm.$set(_vm.vehicle, "carfax_api", $$v)},expression:"vehicle.carfax_api"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.carfax_api}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.TIRE_CONDITION')}`,"placeholder":`${_vm.$t('VEHICLES.TIRE_CONDITION')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.tire_condition),callback:function ($$v) {_vm.$set(_vm.vehicle, "tire_condition", $$v)},expression:"vehicle.tire_condition"}},[_c('tire-condition-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.tire_condition},on:{"tireConditionChanged":(tireCondition) => {
            _vm.vehicle.tire_condition = tireCondition;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tire_condition}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"type":"number","label":`${_vm.$t('VEHICLES.TPMS')}`,"placeholder":`${_vm.$t('VEHICLES.TPMS')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.tpms),callback:function ($$v) {_vm.$set(_vm.vehicle, "tpms", $$v)},expression:"vehicle.tpms"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tpms}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('label',[_vm._v(_vm._s(_vm.$t("VEHICLES.HAS_ORIGINAL_WHEELS")))]),_c('base-radio',{attrs:{"name":"has_original_wheels","radio-value":true,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.HAS_ORIGINAL_WHEELS')}`},on:{"input":(value) => {
          _vm.vehicle.has_original_wheels = value;
        }},model:{value:(_vm.vehicle.has_original_wheels),callback:function ($$v) {_vm.$set(_vm.vehicle, "has_original_wheels", $$v)},expression:"vehicle.has_original_wheels"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.YES"))+" ")]),_c('base-radio',{attrs:{"name":"has_original_wheels","radio-value":false,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.HAS_ORIGINAL_WHEELS')}`},on:{"input":(value) => {
          _vm.vehicle.has_original_wheels = value;
        }},model:{value:(_vm.vehicle.has_original_wheels),callback:function ($$v) {_vm.$set(_vm.vehicle, "has_original_wheels", $$v)},expression:"vehicle.has_original_wheels"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.NO"))+" ")]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.has_original_wheels}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('label',[_vm._v(_vm._s(_vm.$t("VEHICLES.HAS_WINTER_TIRES")))]),_c('base-radio',{attrs:{"name":"has_winter_tires","radio-value":true,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.HAS_WINTER_TIRES')}`},on:{"input":(value) => {
          _vm.vehicle.has_winter_tires = value;
        }},model:{value:(_vm.vehicle.has_winter_tires),callback:function ($$v) {_vm.$set(_vm.vehicle, "has_winter_tires", $$v)},expression:"vehicle.has_winter_tires"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.YES"))+" ")]),_c('base-radio',{attrs:{"name":"has_winter_tires","radio-value":false,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.HAS_WINTER_TIRES')}`},on:{"input":(value) => {
          _vm.vehicle.has_winter_tires = value;
        }},model:{value:(_vm.vehicle.has_winter_tires),callback:function ($$v) {_vm.$set(_vm.vehicle, "has_winter_tires", $$v)},expression:"vehicle.has_winter_tires"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.NO"))+" ")]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.has_winter_tires}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('label',[_vm._v(_vm._s(_vm.$t("VEHICLES.HAS_STUDDED_WINTER_TIRES")))]),_c('base-radio',{attrs:{"name":"has_studded_winter_tires","radio-value":true,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.HAS_STUDDED_WINTER_TIRES')}`},on:{"input":(value) => {
          _vm.vehicle.has_studded_winter_tires = value;
        }},model:{value:(_vm.vehicle.has_studded_winter_tires),callback:function ($$v) {_vm.$set(_vm.vehicle, "has_studded_winter_tires", $$v)},expression:"vehicle.has_studded_winter_tires"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.YES"))+" ")]),_c('base-radio',{attrs:{"name":"has_studded_winter_tires","radio-value":false,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.HAS_STUDDED_WINTER_TIRES')}`},on:{"input":(value) => {
          _vm.vehicle.has_studded_winter_tires = value;
        }},model:{value:(_vm.vehicle.has_studded_winter_tires),callback:function ($$v) {_vm.$set(_vm.vehicle, "has_studded_winter_tires", $$v)},expression:"vehicle.has_studded_winter_tires"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.NO"))+" ")]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.has_studded_winter_tires}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('label',[_vm._v(_vm._s(_vm.$t("VEHICLES.HAS_FOUR_IDENTICAL_TIRES")))]),_c('base-radio',{attrs:{"name":"has_four_identical_tires","radio-value":true,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.HAS_FOUR_IDENTICAL_TIRES')}`},on:{"input":(value) => {
          _vm.vehicle.has_four_identical_tires = value;
        }},model:{value:(_vm.vehicle.has_four_identical_tires),callback:function ($$v) {_vm.$set(_vm.vehicle, "has_four_identical_tires", $$v)},expression:"vehicle.has_four_identical_tires"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.YES"))+" ")]),_c('base-radio',{attrs:{"name":"has_four_identical_tires","radio-value":false,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.HAS_FOUR_IDENTICAL_TIRES')}`},on:{"input":(value) => {
          _vm.vehicle.has_four_identical_tires = value;
        }},model:{value:(_vm.vehicle.has_four_identical_tires),callback:function ($$v) {_vm.$set(_vm.vehicle, "has_four_identical_tires", $$v)},expression:"vehicle.has_four_identical_tires"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.NO"))+" ")]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.has_four_identical_tires}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.SECOND_TIRES')}`,"placeholder":`${_vm.$t('VEHICLES.SECOND_TIRES')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.second_tires),callback:function ($$v) {_vm.$set(_vm.vehicle, "second_tires", $$v)},expression:"vehicle.second_tires"}},[_c('second-tires-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.second_tires},on:{"secondTiresChanged":(secondTires) => {
            _vm.vehicle.second_tires = secondTires;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.second_tires}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.DECLARATIONS')}`,"placeholder":`${_vm.$t('VEHICLES.DECLARATIONS')}`,"disable":_vm.disable,"required":true}},[_c('declarations-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.declarations ?? []},on:{"declarationsChanged":(declarations) => {
            _vm.vehicle.declarations = declarations;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.declarations}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.SELLER_NOTES')}`,"placeholder":`${_vm.$t('VEHICLES.SELLER_NOTES')}`,"disable":_vm.disable},model:{value:(_vm.vehicle.seller_notes),callback:function ($$v) {_vm.$set(_vm.vehicle, "seller_notes", $$v)},expression:"vehicle.seller_notes"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.seller_notes}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }