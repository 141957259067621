<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ vehicle.name }} - {{ $t("COMMON.DETAILS") }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ID") }}</dt>
        <dd class="col-sm-8">{{ vehicle.organization.id }}</dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-8">{{ vehicle.organization.name }}</dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
        <dd class="col-sm-8">{{ vehicle.organization.email }}</dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
        <dd class="col-sm-8">{{ vehicle.organization.address }}</dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
        <dd class="col-sm-8">{{ vehicle.organization.phone }}</dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="vehicle.organization.created_at">
          {{ $timeZoneDateFormat(vehicle.organization.created_at) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="vehicle.organization.updated_at">
          {{ $timeZoneDateFormat(vehicle.organization.updated_at) }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";

export default {
  name: "vehicle-organization-view-global",

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data() {
    return {};
  },
  
  props: ["vehicle"],
};
</script>