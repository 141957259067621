<template>
  <el-select
    v-model="auctionTypeModel"
    @change="auctionTypeChanged"
    :filterable="filterable"
    :disabled="disabled"
    :required="required"
    :placeholder="`${$t('AUCTIONS.AUCTION_TYPE')}`"
  >
    <el-option :value="null" :label="$t('AUCTIONS.AUCTION_TYPE')"></el-option>
    <el-option
      v-for="map in AUCTION_TYPES"
      :key="map"
      :value="map"
      :label="`${$t('AUCTIONS.AUCTION_TYPE_' + map)}`"
    />
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { AUCTION_TYPES } from "@/constants/auctions";

export default {
  name: "auction-type-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    required: {
      type: Boolean,
      default: false,
      description: "Make input required",
    },
    value: {
      type: String,
      default: () => "",
      description: "Value",
    },
  },

  data() {
    const auctionTypeModel = this.value;

    return {
      auctionTypeModel,
      AUCTION_TYPES,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    auctionTypeChanged(auctionType) {
      this.$emit("auctionTypeChanged", auctionType);
    },
  },

  watch: {
    value() {
      this.auctionTypeModel = this.value;
    },
  },
};
</script>
