<template>
  <el-select
    v-model="secondTiresModel"
    @change="secondTiresChanged"
    :placeholder="$t('VEHICLES.SECOND_TIRES')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option :value="null" :label="$t('VEHICLES.SECOND_TIRES')"> </el-option>
    <el-option
      v-for="item in ['Oui (Avec jantes)', 'Oui (Sans jantes)', 'Non']"
      :key="item"
      :label="item"
      :value="item"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "second-tires-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String | Number | null | undefined,
      default: "",
      description: "Value",
    },
    options: {
      type: Array | undefined | null,
      default: null,
    },
  },

  data() {
    const secondTiresModel = this.value;
    return {
      secondTiresModel,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    secondTiresChanged(secondTires) {
      this.$emit("secondTiresChanged", secondTires);
    },
  },

  watch: {
    value() {
      this.secondTiresModel = this.value;
    },
  },
};
</script>
