<template>
  <el-select
    v-model="styleModel"
    @change="styleChanged"
    :placeholder="$t('VEHICLES.STYLE')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option
      v-for="style in styles"
      :key="style.value"
      :value="style.value"
      :label="style.label"
    >
    </el-option>
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "style-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    year: {
      type: Number | String | null,
      default: null,
      description: "Year",
    },
    make: {
      type: String | null,
      default: null,
      description: "Make",
    },
    model: {
      type: String | null,
      default: null,
      description: "Model",
    },
    serie: {
      type: String | null,
      default: null,
      description: "Serie",
    },
    value: {
      type: String | Number | null | undefined,
      default: "",
      description: "Value",
    },
  },

  data() {
    const styleModel = this.value?.toLowerCase();
    return {
      styleModel,
      styles: [],
    };
  },

  setup() {},

  created() {
    this.getstyles();
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getstyles();
    }, 300),

    async getstyles() {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {},
        };
        if (this.year) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              year: this.year,
            },
          };
        }

        if (this.make) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              vmake: this.make,
            },
          };
        }

        if (this.model) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              model: this.model,
            },
          };
        }

        if (this.serie) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              serie: this.serie,
            },
          };
        }

        await this.$store.dispatch("estimateVehicleStyle/list", params);
        const stylesArr = await this.$store.getters[
          "estimateVehicleStyle/list"
        ];
        this.styles = [];
        let tempArr = [];
        stylesArr.forEach((style) => {
          if (!tempArr.includes(style.name?.toLowerCase())) {
            tempArr.push(style.name?.toLowerCase());
            this.styles.push({
              value: style.name?.toLowerCase(),
              label: style.name,
            });
          }
        });
        if (!tempArr.includes(this.styleModel?.toLowerCase())) {
          this.styleModel = "";
          this.styleChanged("");
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    styleChanged(style) {
      this.$emit("styleChanged", style);
    },
  },

  watch: {
    year: {
      handler: "getListDebounced",
      immediate: true,
    },
    make: {
      handler: "getListDebounced",
      immediate: true,
    },
    serie: {
      handler: "getListDebounced",
      immediate: true,
    },
    model: {
      handler: "getListDebounced",
      immediate: true,
    },
    value() {
      this.styleModel = this.value?.toLowerCase();
    },
  },
};
</script>
