export const FUEL_GAS = "GAS";
export const FUEL_HYBRID = "HYBRID";
export const FUEL_ELECTRIC = "ELECTRIC";
export const FUEL_PHEV = "PHEV"; //PHEV (Véhicule hybride électrique rechargeable)
export const FUEL_DIESEL = "DIESEL";

export const fuelTypes = {
  [FUEL_GAS]: "gas",
  [FUEL_HYBRID]: "hybrid",
  [FUEL_ELECTRIC]: "electric",
  [FUEL_PHEV]: "phev",
  [FUEL_DIESEL]: "diesel",
};
