<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $objectDenomination(vehicle) }}</h3>

    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.VIN") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.vin }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.SKU") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.sku }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MODEL_YEAR") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.model_year }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MAKE") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.make }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MODEL") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.model }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.SERIES") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.series }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.STYLE") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.style }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.OUTSIDE_COLOR") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.outside_color }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.INSIDE_COLOR") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.inside_color }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ENGINE_DESCRIPTION") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.engine_description }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.GEARBOX") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.transmission }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.FUEL_TYPE") }}</dt>
        <dd class="col-sm-8">
          {{
            vehicle.fuel_type
              ? $t(`FUEL_TYPES.${vehicle.fuel_type.toUpperCase()}`)
              : "-"
          }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ODOMETER") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.odometer_mileage }} {{ vehicle.odometer_type }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.TRANSMISSION") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.drivetrain }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.NB_PASSENGERS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.nb_passengers }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.NB_DOORS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.nb_doors }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ACCESSORIES") }}</dt>
        <dd class="col-sm-8">
          <ul>
            <li v-for="(a, i) in vehicle.accessories" :key="i">
              {{ a }}
            </li>
          </ul>
        </dd>
      </dl>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.CONDITION") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.condition }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">
          {{ $t("VEHICLES.OWNERSHIP_CAN_BE_TRANSFERRED") }}
        </dt>
        <dd class="col-sm-8">
          {{
            vehicle.ownership_can_be_transferred
              ? $t("COMMON.YES")
              : $t("COMMON.NO")
          }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MUST_BE_TOWED") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.must_be_towed ? $t("COMMON.YES") : $t("COMMON.NO") }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.CARFAX_RECLAIMS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.carfax_reclaims }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.CARFAX_REPORTING_ENABLED") }}</dt>
        <dd class="col-sm-8">
          {{
            vehicle.carfax_reporting_enabled
              ? $t("COMMON.YES")
              : $t("COMMON.NO")
          }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.CARFAX_API") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.carfax_api }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.TIRE_CONDITION") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.tire_condition }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.TPMS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.tpms }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.HAS_ORIGINAL_WHEELS") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.has_original_wheels ? $t("COMMON.YES") : $t("COMMON.NO") }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.HAS_WINTER_TIRES") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.has_winter_tires ? $t("COMMON.YES") : $t("COMMON.NO") }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.HAS_STUDDED_WINTER_TIRES") }}</dt>
        <dd class="col-sm-8">
          {{
            vehicle.has_studded_winter_tires
              ? $t("COMMON.YES")
              : $t("COMMON.NO")
          }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.HAS_FOUR_IDENTICAL_TIRES") }}</dt>
        <dd class="col-sm-8">
          {{
            vehicle.has_four_identical_tires
              ? $t("COMMON.YES")
              : $t("COMMON.NO")
          }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.SECOND_TIRES") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.second_tires }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.DECLARATIONS") }}</dt>
        <dd class="col-sm-8">
          <ul>
            <li v-for="(a, i) in vehicle.declarations" :key="i">
              {{ a }}
            </li>
          </ul>
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.SELLER_NOTES") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.seller_notes }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.DEALER_NAME") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.location?.name ?? vehicle.organization?.name ?? "" }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.AUCTION_DATE") }}</dt>
        <dd class="col-sm-8">
          {{
            vehicle.auction_date ? $formatDate(vehicle.auction_date, "LL") : ""
          }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.PICKUP_LOCATION") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.pickup_location }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.BUY_NOW_PRICE") }}</dt>
        <dd class="col-sm-8">
          {{ $formatCurrency(vehicle.buy_now_price) }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MIN_BID_AMOUNT") }}</dt>
        <dd class="col-sm-8">
          {{ $formatCurrency(vehicle.min_bid_amount) }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.RESERVE_PRICE") }}</dt>
        <dd class="col-sm-8">
          {{ $formatCurrency(vehicle.reserve_price) }}
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <badge
            :type="vehicle.status === VEHICLE_STATUS_COMPLETED ? 'success' : 'warning'"
          >
            {{ $t(`COMMON.${vehicle.status.toUpperCase() ?? "DRAFT"}`) }}
          </badge>
        </dd>
      </div>

      <div class="row">
        <dt class="col-sm-4">{{ $t("COMMON.VALIDATED") }}</dt>
        <dd class="col-sm-8">
          <badge :type="vehicle.is_validated ? 'success' : 'warning'">
            {{ vehicle.is_validated ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </badge>
        </dd>
      </div>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="vehicle.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="vehicle.created_at">
          {{ $timeZoneDateFormat(vehicle.created_at) }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="vehicle.updated_at">
          {{ $timeZoneDateFormat(vehicle.updated_at) }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { VEHICLE_STATUS_COMPLETED } from "@/constants/vehicles";

export default {
  name: "vehicle-view-global",

  components: {},

  props: ["vehicle"],

  data() {
    return {
      VEHICLE_STATUS_COMPLETED: VEHICLE_STATUS_COMPLETED,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
