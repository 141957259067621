var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-select',{attrs:{"filterable":_vm.filterable,"disabled":_vm.disabled,"placeholder":`${_vm.$t('VEHICLES.CAR_TYPE')}`},on:{"change":_vm.carTypeChanged},model:{value:(_vm.carTypeModel),callback:function ($$v) {_vm.carTypeModel=$$v},expression:"carTypeModel"}},[_c('el-option',{attrs:{"value":null,"label":_vm.$t('VEHICLES.CAR_TYPE')}}),_vm._l(([
      'Décapotable',
      'Coupé',
      'A Hayon',
      'Berline',
      'Vus',
      'Camion',
      'Fourgonnette',
      'Familiale',
      'Commercial',
    ]),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }