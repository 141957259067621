<template>
  <div style="width: 100%">
    <div class="inspection-form-title">
      <h3>{{ $t("VEHICLES.VEHICLE_IDENTIFICATION") }}</h3>
    </div>

    <div class="inspection-form-divider"></div>

    <el-steps :active="activeStep" finish-status="success">
      <el-step title="Etape 1" />
      <el-step title="Etape 2" />
      <el-step title="Etape 3" />
      <el-step title="Etape 4" />
    </el-steps>

    <component
      :is="currentStepComponent"
      ref="currentForm"
      :vehicle-data="vehicle"
      :form-errors="formErrors"
      :loading="loading"
      :disable="disable"
      @formChanged="
        (form) => {
          console.log('form changed', form);

          vehicle = { ...vehicle, ...form };

          onFormChanged();
        }
      "
      @submitted="handleSubmit"
    />

    <div class="step-actions">
      <el-button
        @click="prevStep"
        :loading="loading"
        :disable="disable || activeStep === 0"
      >
        Previous
      </el-button>

      <el-button
        type="primary"
        @click="nextStep"
        :loading="loading"
        :disable="disable"
      >
        {{ activeStep === 3 ? saveButtonText : "Suivant" }}
      </el-button>
    </div>
  </div>
</template>

<script>
import _, { cloneDeep } from "lodash";
import { Button, Step, Steps } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import Step1 from "@/components/Vehicle/Steps/Step1.vue";
import Step2 from "@/components/Vehicle/Steps/Step2.vue";
import Step3 from "@/components/Vehicle/Steps/Step3.vue";
import Step4 from "@/components/Vehicle/Steps/Step4.vue";
import { VEHICLE_STATUS_COMPLETED } from "@/constants/vehicles";

export default {
  layout: "DashboardLayout",

  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: {
    vehicleData: { type: Object },
    formErrors: { type: Object | Array, default: null },
    loading: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
  },

  data() {
    const vehicleData = cloneDeep(this.vehicleData);

    console.log("vehicle data", vehicleData.status);
    console.log("vehicle data", vehicleData.current_step);

    const nextTab =
      vehicleData.current_step === undefined ||
      vehicleData.status === VEHICLE_STATUS_COMPLETED
        ? 0
        : vehicleData.current_step < 3
        ? (vehicleData.current_step ?? 0) + 1
        : 3;

    console.log("generated next tab", nextTab);

    return {
      vehicle: vehicleData,
      vehicleBackup: vehicleData,
      activeStep: nextTab,
    };
  },

  computed: {
    currentStepComponent() {
      return [Step1, Step2, Step3, Step4][this.activeStep] || Step1;
    },
    saveButtonText() {
      if (this.vehicle && this.vehicle.id && this.vehicle.id !== "undefined") {
        return this.$t("VEHICLES.EDIT_VEHICLE");
      } else {
        return this.$t("VEHICLES.ADD_VEHICLE");
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    nextStep() {
      this.$refs.currentForm.submit();
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      }
    },
    async handleSubmit(stepData) {
      let vehicleData = cloneDeep(stepData);

      if (
        !this.$currentUserCan(this.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
      ) {
        vehicleData = this.$fillUserOrganizationData(vehicleData);
      }

      this.$emit("vehicleSubmitted", vehicleData);
    },

    reset() {
      this.vehicle = { ...this.vehicle, ...this.vehicleBackup };
    },

    onFormChanged() {
      this.$emit("formChanged", this.vehicle);
    },
  },

  watch: {
    // "$route.query": {
    //   handler(query) {
    //     console.log("watch $route query", query);

    //     this.activeStep = parseInt(query.tab ?? "0") ?? 0;
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData: {
      handler(vehicleData) {
        console.log("watch vehicle data", vehicleData);

        if (vehicleData) {
          this.vehicle = {
            ...this.vehicle,
            ...cloneDeep(vehicleData),
          };
        }
      },
    },
    vehicle: {
      handler: function (value) {
        if (_.isEqual(value, this.vehicle)) return;

        this.$emit("formChanged", this.vehicle);
      },
      deep: true,
    },
  },
};
</script>
