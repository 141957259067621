<template>
  <div class="container-fluid">
    <vehicle-form
      ref="form"
      :loading="loading"
      :vehicle-data="vehicle"
      :form-errors="formErrors"
      @vehicleSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultVehicle from "../defaultVehicle";
import VehicleForm from "../partials/VehicleForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    VehicleForm,
  },

  mixins: [alertLeave],

  data() {
    const vehicle = cloneDeep(defaultVehicle);

    return {
      vehicle,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(vehicle) {
      this.loading = true;

      const vehicleData = cloneDeep(vehicle);

      if (!vehicleData.organization?.id) {
        delete vehicleData.organization;
      }

      if (!vehicleData.location?.id) {
        delete vehicleData.location;
      }

      delete vehicleData.id;

      try {
        await this.$store.dispatch("vehicles/add", vehicleData);

        this.$notify({
          type: "success",
          message: this.$t("VEHICLES.VEHICLE_ADDED"),
        });

        this.vehicle = this.$store.getters["vehicles/vehicle"];

        this.$emit("onEditVehicle", this.vehicle, true);

        // this.$refs.form.activeStep++;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.formErrors = error.response?.data?.errors ?? [];
      }

      this.loading = false;
    },
  },
};
</script>
