<template>
  <el-select
    v-model="accessoriesModel"
    @change="accessoriesChanged"
    :filterable="filterable"
    multiple
    remote
    :remote-method="getAccessories"
    :loading="loading"
    :disabled="disabled"
    :placeholder="$t('VEHICLES.ACCESSORIES')"
  >
    <el-option
      v-for="item in accessoriesList"
      :key="item.id"
      :label="item.name"
      :value="item.name"
    />
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "accessories-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: Array,
      default: () => [],
      description: "Value",
    },
  },

  data() {
    const accessoriesModel = this.value;
    return {
      loading: false,
      accessoriesModel,
      accessoriesList: {},
    };
  },

  setup() {},

  created() {
    this.getAccessories(null);
  },

  computed: {},

  methods: {
    async getAccessories(query = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            category: "option",
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        await this.$store.dispatch("labels/list", params);
        this.accessoriesList = this.$store.getters["labels/list"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
    accessoriesChanged(accessories) {
      this.$emit("accessoriesChanged", accessories);
    },
  },

  watch: {
    value() {
      this.accessoriesModel = this.value;
    },
  },
};
</script>
