export default {
  type: "vehicles",
  sku: "",
  vin: "",
  make: "",
  model: "",
  series: "",
  model_year: "",
  style: "",
  engine_description: "",
  fuel_type: "",
  transmission: "",
  drivetrain: "",
  registration_number: "",
  odometer_mileage: "",
  odometer_type: "",
  outside_color: "",
  inside_color: "",
  car_type: "",
  nb_passengers: "",
  nb_doors: "",
  accessories: [],
  condition: "",
  ownership_can_be_transferred: "",
  must_be_towed: "",
  carfax_reclaims: "",
  carfax_reporting_enabled: "",
  carfax_api: "",
  tire_condition: "",
  tpms: "",
  has_original_wheels: "",
  has_winter_tires: "",
  has_studded_winter_tires: "",
  has_four_identical_tires: "",
  second_tires: "",
  declarations: [],
  seller_notes: "",
  exterior_photos: "",
  interior_photos: "",
  damages: [],
  auction_type: "",
  auction_date: "",
  pickup_location: "",
  buy_now_price: "",
  min_bid_amount: "",
  reserve_price: "",
  relationshipNames: ["organization", "location"],
  organization: {
    type: "organizations",
    id: null,
    email: null,
    address: null,
    phone: null,
    created_at: null,
    updated_at: null,
  },
  location: {
    type: "locations",
    id: null,
  },
};
