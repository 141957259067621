var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-select',{attrs:{"placeholder":_vm.$t('VEHICLES.INSIDE_COLOR'),"filterable":_vm.filterable,"disabled":_vm.disabled},on:{"change":_vm.insideColorChanged},model:{value:(_vm.insideColorModel),callback:function ($$v) {_vm.insideColorModel=$$v},expression:"insideColorModel"}},[_c('el-option',{attrs:{"value":null,"label":_vm.$t('VEHICLES.INSIDE_COLOR')}}),_vm._l(([
      'Noir',
      'Blanc',
      'Gris',
      'Bleu',
      'Vert',
      'Rouge',
      'Jaune',
    ]),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }