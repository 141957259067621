<template>
  <el-select
    v-model="vehicleModel"
    @change="vehicleChanged"
    autocomplete="new-password"
    :placeholder="vin ? vin : $t('COMMON.VEHICLE')"
    :filterable="true"
    :multiple="false"
    :disabled="disabled"
    remote
    :remote-method="getVehicles"
    :loading="loading"
  >
    <el-option
      v-for="vehicle in vehicles"
      :key="vehicle.vehicle_id"
      :value="vehicle.vehicle_id"
      :label="$objectDenomination({ ...vehicle, type: 'vehicles' })"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    vin: {
      type: String,
      default: null,
      description: "Vin",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "organizationID",
    },
  },

  data() {
    return {
      vehicleModel: this.vin,
      vehicles: {},
      loading: false,
    };
  },

  setup() {},

  created() {
    this.getVehicles(this.vin);
  },

  methods: {
    getVehiclesDebounced: _.debounce(function () {
      this.getVehicles();
    }, 300),

    async getVehicles(query = "") {
      if (!query) {
        return;
      }
      try {
        this.loading = true;
        const params = {
          vin: query,
          organization: this.filterOrganization,
        };
        await this.$store.dispatch("vehicles/lookupApiByVin", params);
        this.vehicles = this.$store.getters["vehicles/apiVehicles"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    vehicleChanged(vehicle_id) {
      const vehicleData = this.vehicles.find(
        (item) => item.vehicle_id == vehicle_id
      );
      this.$emit("vehicleSelected", vehicleData);
    },
  },

  watch: {
    vin(vin) {
      this.vehicleModel = vin;
    },
  },
};
</script>
