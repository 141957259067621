var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"form",staticClass:"inspection-form",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"inspection-form-group width-1 gallery"},[_c('label',[_vm._v(_vm._s(_vm.$t("VEHICLES.EXTERIOR_PHOTOS")))]),_c('gallery-selector',{attrs:{"label":`${_vm.$t('VEHICLES.EXTERIOR_PHOTOS')}`,"defaultGallery":_vm.vehicle.exterior_photos,"ressource_name":"vehicles","ressource_id":_vm.vehicle.id ? _vm.vehicle.id : 0,"field":"exterior_photos","disabled":_vm.disable,"required":true},on:{"galleryChanged":(exteriorPhotos) => {
          _vm.vehicle.exterior_photos = exteriorPhotos;
        }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.exterior_photos}})],1),_c('div',{staticClass:"inspection-form-group width-1 gallery"},[_c('label',[_vm._v(_vm._s(_vm.$t("VEHICLES.INTERIOR_PHOTOS")))]),_c('gallery-selector',{attrs:{"label":`${_vm.$t('VEHICLES.INTERIOR_PHOTOS')}`,"defaultGallery":_vm.vehicle.interior_photos,"ressource_name":"vehicles","ressource_id":_vm.vehicle.id ? _vm.vehicle.id : 0,"field":"interior_photos","disabled":_vm.disable,"required":true},on:{"galleryChanged":(interiorPhotos) => {
          _vm.vehicle.interior_photos = interiorPhotos;
        }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.interior_photos}})],1),_c('div',{staticClass:"inspection-form-group width-1 gallery"},[_c('div',{staticStyle:{"display":"flex","gap":"2em","align-items":"center"}},[_c('div',[_vm._v(_vm._s(_vm.$t("VEHICLES.DAMAGES")))]),_c('base-button',{staticClass:"btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm",attrs:{"icon":"","size":"sm"},on:{"click":() => (_vm.open = true)}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-edit"})])]),_c('modal',{attrs:{"show":_vm.open,"modal-classes":"modal-secondary text-left"}},[_c('h2',[_vm._v("Spécifier les emplacements")]),_c('p',[_vm._v(" Vous pouvez assigner une localisation précise aux dommages capturés en photo ")]),_vm._l((_vm.vehicle.damages),function(item,key){return _c('div',{key:key,staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('gallery-selector',{attrs:{"label":`${_vm.$t('COMMON.PICTURE')}`,"defaultGallery":[item.photo],"ressource_name":"vehicles","ressource_id":_vm.vehicle.id ? _vm.vehicle.id : 0,"field":"exterior_photos","disabled":""}})],1),_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":`${_vm.$t('COMMON.LOCATION')}`,"disable":_vm.disable,"required":""},model:{value:(item.location),callback:function ($$v) {_vm.$set(item, "location", $$v)},expression:"item.location"}},[_c('damage-location-selector',{attrs:{"filterable":true,"value":item.location},on:{"locationChanged":(location) => {
                    item.location = location;
                  }}})],1)],1)])}),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-elite black",attrs:{"type":"button"},on:{"click":() => (_vm.open = false)}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")]),_c('button',{staticClass:"btn ml-auto btn-elite save",attrs:{"type":"button"},on:{"click":() => (_vm.open = false)}},[_vm._v(" "+_vm._s(_vm.$t("NOTIFICATIONS.SAVE"))+" ")])])],2)],1),_c('gallery-selector',{attrs:{"label":`${_vm.$t('VEHICLES.DAMAGES')}`,"defaultGallery":_vm.vehicle.damages.map((map) => map.photo),"ressource_name":"vehicles","ressource_id":_vm.vehicle.id ? _vm.vehicle.id : 0,"field":"damages","disabled":_vm.disable},on:{"galleryChanged":(photos) => {
          _vm.vehicle.damages = photos.map((photo, idx) => {
            return {
              photo,
              location: _vm.vehicle.damages[idx]?.location ?? '',
            };
          });
        }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.damages}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }