<template>
  <el-select
    v-model="outsideColorModel"
    @change="outsideColorChanged"
    :placeholder="$t('VEHICLES.OUTSIDE_COLOR')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option :value="null" :label="$t('VEHICLES.OUTSIDE_COLOR')"> </el-option>
    <el-option
      v-for="item in [
        'Noir',
        'Blanc',
        'Gris',
        'Bleu',
        'Vert',
        'Rouge',
        'Jaune',
      ]"
      :key="item"
      :label="item"
      :value="item"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "outside-color-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String | Number | null | undefined,
      default: "",
      description: "Value",
    },
    options: {
      type: Array | undefined | null,
      default: null,
    },
  },

  data() {
    const outsideColorModel = this.value;
    return {
      outsideColorModel,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    outsideColorChanged(outsideColor) {
      this.$emit("outsideColorChanged", outsideColor);
    },
  },

  watch: {
    value() {
      this.outsideColorModel = this.value;
    },
  },
};
</script>
