<template>
  <div class="vehicle-images">
    <div class="vehicle-images-item">
      <div class="vehicle-images-item-name">
        {{ $objectDenomination(vehicle) }} - {{ $t("VEHICLES.PICTURES") }}
      </div>

      <p>{{ $t("VEHICLES.EXTERIOR_PHOTOS") }}</p>
      <div class="vehicle-images-item-images">
        <div
          class="vehicle-images-item-images-item"
          v-for="(imageUrl, idx) in vehicle.exterior_photos"
          :key="`vehicle-${vehicle.id}-${idx}`"
        >
          <img :src="imageUrl" alt="logo" />
        </div>
      </div>

      <br>

      <p>{{ $t("VEHICLES.INTERIOR_PHOTOS") }}</p>
      <div class="vehicle-images-item-images">
        <div
          class="vehicle-images-item-images-item"
          v-for="(imageUrl, idx) in vehicle.interior_photos"
          :key="`vehicle-${vehicle.id}-${idx}`"
        >
          <img :src="imageUrl" alt="logo" />
        </div>
      </div>

      <br>

      <p>{{ $t("VEHICLES.DAMAGES") }}</p>

      <ul>
        <li v-for="(d, idx) in vehicle.damages" :key="idx">
          <img :src="d.photo" alt="logo" />

          {{ d.location }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";

export default {
  name: "vehicle-view-images",

  components: { [Collapse.name]: Collapse, [CollapseItem.name]: CollapseItem },

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
