<template>
  <el-select
    v-model="odometerTypeModel"
    @change="odometerTypeChanged"
    :filterable="filterable"
    :disabled="disabled"
    :required="required"
    :placeholder="`${$t('VEHICLES.ODOMETER_TYPE')}`"
  >
    <el-option :value="null" :label="$t('VEHICLES.ODOMETER_TYPE')"></el-option>
    <el-option value="kms" label="KMS"></el-option>
    <el-option value="miles" label="MILES"></el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "odometer-type-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    required: {
      type: Boolean,
      default: false,
      description: "Make input required",
    },
    value: {
      type: String,
      default: () => "",
      description: "Value",
    },
  },

  data() {
    const odometerTypeModel = this.value?.toLowerCase();
    return {
      odometerTypeModel,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    odometerTypeChanged(odometerType) {
      this.$emit("odometerTypeChanged", odometerType);
    },
  },

  watch: {
    value() {
      this.odometerTypeModel = this.value?.toLowerCase();
    },
  },
};
</script>
